// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

const Dashboard = ({ user }) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [error, setError] = useState(null);
  const [lookerUrl, setLookerUrl] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeKey, setIframeKey] = useState(0); // Added state for iframe key

  useEffect(() => {
    if (user === undefined) {
      // User state is still loading
      return;
    }

    if (!user) {
      // No user is logged in
      setAccounts([]);
      return;
    }

    // Fetch accounts where 'authorizedUsers' array contains the user's UID
    const accountsRef = collection(db, 'accounts');
    const q = query(
      accountsRef,
      where('authorizedUsers', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const accountsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAccounts(accountsData);
        setError(null);

        // Determine the selected account ID
        let savedAccountId = localStorage.getItem('selectedAccountId');
        const validAccountIds = accountsData.map((account) => account.id);

        if (savedAccountId && validAccountIds.includes(savedAccountId)) {
          setSelectedAccountId(savedAccountId);
        } else if (accountsData.length > 0) {
          // Default to the first account if no valid saved account ID
          setSelectedAccountId(accountsData[0].id);
          localStorage.setItem('selectedAccountId', accountsData[0].id);
        } else {
          setSelectedAccountId('');
        }
      },
      (error) => {
        console.error('Error fetching accounts:', error);
        setError('Failed to fetch accounts. Please try again.');
      }
    );

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (selectedAccountId) {
      // Reset iframeLoaded state
      setIframeLoaded(false);

      // Save the selected account ID to localStorage
      localStorage.setItem('selectedAccountId', selectedAccountId);

      // Construct the Looker Studio URL
      const baseUrl =
        'https://lookerstudio.google.com/embed/reporting/14f39546-394d-4a8a-9b96-bd321589e92e/page/cKyDE';
      const params = encodeURIComponent(
        JSON.stringify({ filterAccountId: selectedAccountId })
      );
      const url = `${baseUrl}?params=${params}`;
      setLookerUrl(url);
    }
  }, [selectedAccountId]);

  const handleAccountChange = (event) => {
    setSelectedAccountId(event.target.value);
    // The useEffect will handle updating the Looker URL and localStorage
  };

  const handleRefresh = () => {
    setIframeLoaded(false);
    setIframeKey((prevKey) => prevKey + 1);
  };

  // Handle loading state
  if (user === undefined) {
    return (
      <Container>
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} sx={{ maxWidth: '1400px' }}>
      {/* Title */}
      <Typography variant="h4" sx={{ marginTop: 2 }}>
        Dashboard
      </Typography>

      {/* Account Dropdown with Refresh Icon - below the title and right-aligned */}
      {accounts.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="account-select-label">Select Account</InputLabel>
            <Select
              labelId="account-select-label"
              id="account-select"
              value={selectedAccountId}
              label="Select Account"
              onChange={handleAccountChange}
            >
              {accounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton onClick={handleRefresh} sx={{ ml: 1 }}>
            <RefreshIcon />
          </IconButton>
        </Box>
      ) : (
        <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
          You have no authorized accounts.
        </Typography>
      )}

      {/* Display error if any */}
      {error && (
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* Looker Studio Dashboard */}
      {lookerUrl && (
        <Box
          sx={{
            width: '100%',
            margin: '32px auto 0',
          }}
        >
          <iframe
            key={iframeKey} // Use iframeKey to force re-render
            title="Looker Studio Dashboard"
            src={lookerUrl}
            width="100%"
            height="1200px"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            onLoad={() => setIframeLoaded(true)}
          ></iframe>
        </Box>
      )}
    </Container>
  );
};

export default Dashboard;
